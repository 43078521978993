* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html,
body {
  width: 100%;
  height: 100%;
  font-family: "Quicksand";
}

/* ========== */

.dropbtn {
  /* background-color: #04AA6D;
    color: white;
    padding: 16px;
    font-size: 16px; */
  border: none;
}

/* .dropdown {
  position: relative;
  display: inline-block;
} */

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 800px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 20px 20px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* ==================== parterner slide infinite animation */

.slide-animation {
  overflow: hidden;
  /* background: linear-gradient(
    to left,
    rgba(12, 12, 12, 0.844) 0%,
    rgba(255, 255, 255, 0) 100%
  ); */
  animation: slide 50s linear infinite;
}
@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.span {
  position: relative;
  height: 170px;
  display: block;
  text-align: center;
  padding-top: 110px;
  margin: 80px 20px !important;
  font-weight: bold;
}
.span:before {
  border-left: 2px solid #fff;
  content: "";
  display: block;
  height: 110px;
  left: 10px;
  position: absolute;
  top: -30px;
  width: 1px;
  z-index: 0;
}

.span:after {
  border-left: 2px solid #fff;
  content: "";
  display: block;
  height: 110px;
  left: 10px;
  position: absolute;
  top: 160px;
  width: 1px;
  z-index: 0;
}

@media only screen and (max-width: 767px) {
  .span {
    display: none;
  }
  .img-app {
    width: 100%;
    max-width: 200px;
  }
}

/* =============silk carousel */
.slick-prev:before,
.slick-next:before {
  content: none !important;
}

.slick-prev:before,
.slick-next:before {
  left: 0 !important;
}

.slick-prev:before {
  left: 0 !important;
}

.slick-prev,
.slick-next {
  top: 0 !important;
  height: 100px !important;
}

.slick-next {
  right: 220px !important;
}

.slick-prev {
  left: 1110px !important;
  margin-right: 100px;
}

.self-class {
  height: 340px;
  overflow: scroll;
  /* background-color: aqua; */
}

.sticky-component {
  /* Your component styles go here */
  padding: 20px;
  border: 1px solid #ccc;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #f43737;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}
