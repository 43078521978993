.registerContainer {
  width: 100%;
  height: 90vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  display: flex;
  justify-content: center;
  background-image: url("../../assets/Images/registerimg.jpg");
}

.loginContainer {
  width: 100%;
  height: 90vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  background-image: url("../../assets/Images/loginimg.jpg");
  /* linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), */
}

.payment-container {
  width: 100%;
  height: 90vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  /* align-items: center; */
  /* align-content: center; */
  justify-content: center;
  background: #ff5f5f;
}

.flexContainer {
  display: flex;
  align-items: center;
  /* background-color: #f44336; */
}
.header {
  font-size: 3rem;
  font-weight: bolder;
  color: #424242;
  margin-bottom: 10px;
}
.subHeader {
  font-size: 1.1rem;
  font-weight: 500;
  color: #424242;
  margin-bottom: 8px;
}
.formHolder {
  margin: 16px 0;
}
.textBtnContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.formContainer {
  width: 30%;
}
.forgotText {
  /* font-weight: bolder; */
  /* color: #424242; */
  cursor: pointer;
}

.forgotText:hover {
  color: #009974;
}
.termsText {
  font-size: 1rem;
  font-weight: 500;
  /* color: #424242; */
  /* margin-top: 13px; */
}
.error {
  font-weight: 600;
  color: #f44336;
}
@media screen and (max-width: 700px) {
  .formContainer {
    width: 90%;
  }
}
